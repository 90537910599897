/* Footer container styles */
.footer-container {
    flex-shrink: 0;
    height: 60px;
    display: flex;
    flex-direction: column;
    margin: 0 auto; /* Center the container horizontally */
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: black;
    margin-top: 100px; /* Offset the top margin */
    padding-top: 20px; /* Padding for alignment */
  }
  
  /* Flex container for footer text and icons */
  .footer-content {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Align text and icons vertically */
    font-family: 'Sovereign';
    gap: 10px; /* Space between text and icons */
  }
  
  /* Footer text styling */
  .footer-text {
    color: #7B7D7D;
    font-family: 'Sovereign';
    font-size: 12px;
  }
  
  /* Footer email styling */
  .footer-email {
    font-family: 'Sovereign';
    font-size: 10px;
    margin-top: -10px;
    margin-right: 15px;
    text-align: center;
  }
  
  /* Instagram icon styling */
  .footer-instagram-icon {
    color: #7B7D7D !important;
    padding-left: 10px;
    margin-right: 10px;
    transition: color 0.3s ease;
  }
  
  .footer-instagram-icon:hover {
    color: #f4c20d; /* Change color on hover */
  }

  /* Styling for email link */
.footer-email-link {
    color: #D3AA1A; /* Set your desired color */
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .footer-email-link:hover {
    color: #f4c20d; /* Color when hovered */
  }
  
  
  /* Media query for mobile view */
  @media (max-width: 678px) {
    .footer-container {
      width: 100%;
      height: 100%;
    }
  
    .footer-content {
      flex-direction: column; /* Stack text and icons vertically on mobile */
      align-items: center;
    }
  
    .footer-email {
      margin-top: 10px; /* Adjust spacing on mobile */
    }
  }
  