.cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ddd;
    width: 100%;
    font-family: 'Sovereign', serif;
    width: 100%; /* Set the width to match the homepage */
    max-width: 1050px; /* Adjust max-width as needed */
}

/* Left section for image, product name, price, and remove button */
.cart-item-left {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 2;
}

.cart-item img {
    width: 100px;
    height: auto;
    border-radius: 5px;
    margin-right: 15px; /* Add spacing between image and details */
}

.cart-item-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: 'Sovereign', serif;
}

.cart-item-info h4 {
    font-size: 1.2em;
    margin-bottom: 5px;
    color: white;
}

.cart-item-info div {
    font-size: 1em;
    color: white;
    margin-bottom: 5px;
}

.cart-item-info button {
    background-color: transparent;
    color: #d3aa1ad9;
    border: none;
    cursor: pointer;
    font-family: 'Sovereign', serif;
    padding: 5px;
    margin-top: 5px;
    text-decoration: underline;
}

/* Right section for quantity and total */
.cart-item-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    flex: 1;
    max-width: 200px; /* Limit the width to prevent overflow */
    padding-left: 10px; /* Add padding to give some space from the left */
    box-sizing: border-box; /* Ensure padding is included in the width */
}

/* Ensure the overall cart item container behaves correctly */
.cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
    font-family: 'Sovereign', serif;
}


.cart-item-right input[type="number"] {
    width: 50px;
    padding: 5px;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 5px;
}

.cart-item-right div {
    font-size: 1.1em;
    color: #4fa396;
    margin-bottom: 5px;
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .cart-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .cart-item img {
        width: 80px;
        margin-bottom: 10px;
    }

    .cart-item-left {
        margin-bottom: 10px;
    }

    .cart-item-right {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .cart-item-right input[type="number"] {
        width: 40px;
    }

    .cart-item-right div {
        text-align: left;
    }
}
