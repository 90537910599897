/* General header styles */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #1B1B1B;
    height: 75px;
    width: 100%;
    max-width: 975px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    z-index: 1000;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 4px rgba(211, 170, 26, 0.6);
}

.header-container.scrolled {
    box-shadow: 0 4px 8px rgba(211, 170, 26, 0.8);
}

.logo-link .logo {
    color: rgb(248, 244, 240);
    text-shadow: 0 4px 8px rgba(211, 170, 26, 0.8);
    text-decoration: none;
    font-size: 48px;
    font-family: 'Bublest', sans-serif;
    padding-left: 15px;
}

.navigation {
    display: flex;
    align-items: center;
    padding-right: 15px;
    position: relative;
}

.nav-link {
    color: rgb(248, 244, 240);
    text-decoration: none;
    font-family: 'Spot', sans-serif;
    margin: 0 15px;
    font-size: 18px;
}

.nav-link:hover {
    color: #D3AA1A;
}

.item-count {
    background-color: #D3AA1A;
    color: #1B1B1B;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 14px;
    position: relative;
    top: -8px;
    left: 5px;
}

.menu-toggle {
    display: none; /* Hidden by default */
    background: none;
    border: none;
    color: rgb(248, 244, 240);
    font-size: 24px;
    cursor: pointer;
    z-index: 1100; /* Ensure it's on top of everything */
}

/* Mobile view styles */
@media (max-width: 768px) {
    .navigation {
        display: none;
        flex-direction: column;
        background-color: #1B1B1B;
        position: absolute;
        top: 75px; /* Adjusted to match header height */
        left: 0;
        width: 100%;
        padding: 20px 0;
        align-items: center; /* Center all links horizontally */
        transition: all 0.3s ease; /* Smooth open/close transition */
        z-index: 1000; /* Ensure it's above most content */
    }

    .navigation.active {
        display: flex;
    }

    .menu-toggle {
        display: block;
    }

    .nav-link {
        margin: 10px 0; /* Center all links with even spacing */
        width: 100%;
        text-align: center; /* Center text inside the links */
        padding: 10px 0;
        border-bottom: 1px solid #333;
    }
}
