/* Apply global styles */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  background-color: #000; /* Set the background color to black */
  color: #fff; /* Set the text color to white for contrast */
  box-sizing: border-box; /* Ensure padding and borders are included in width and height */
  width: 100%;
}

/* Container for the entire app */
.app-container {
  max-width: 1050px;
  width: 100%;
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Optional: Add some padding around your content */
  background-color: #000; /* Ensure the background color is black */
  color: #fff; /* Ensure the text is readable */
  min-height: 100vh; /* Ensure the container takes at least the full height of the viewport */
  
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure space between header, content, and footer */
  align-items: center; /* Center content horizontally */
  box-sizing: border-box; /* Include padding and borders in width calculation */
  overflow-x: hidden; /* Prevent any horizontal scrolling */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .app-container {
    padding: 10px; /* Reduce padding on smaller screens */
    overflow-x: hidden; /* Ensure no horizontal scrolling on mobile */
  }

  header, footer {
    padding: 5px 0; /* Adjust padding for header/footer on smaller screens */
    width: 100%;
  }
}
