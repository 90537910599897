.main-emailsent-container {
    margin-top: 120px; /* Add top margin to create space below the header */
    width: 100%; /* Set the width to match the homepage */
    max-width: 1050px; /* Adjust max-width as needed */
    margin: 0 auto; /* Center the container */
    background-color: #343434; /* Set the background color to white or any other color */
    
  }

  .emailsent-container {
    margin-top: 40px;
    width: 100%;
    height: 100%;
    display: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    border-bottom: 1px solid #ccc; /* Add border around the filter component */
  }



  
  @media screen and (max-width: 767px) {

    .main-emailsent-container {
      margin-top: 75px; /* Adjust the margin to create more space below the header */
    }
}  