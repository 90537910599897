/* Styles for the main container */
.main-container {
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
    text-align: center;
    padding-top: 60px;
    background-color: #343434;  
    border-bottom: 1px solid #ccc; /* Add border around the filter component */
  }
  
  .main-products-title {
    font-family: 'Bublest', serif;
    font-size: 56px;
    color: rgb(248, 244, 240);
    margin-bottom: 20px;
    text-shadow: 6px -3px 8px rgba(79, 163, 150, .8);
  }
  
  .product-text h3 {
    font-family: 'Bublest';
    font-size: 64px;
    color: white;
    text-shadow: 6px -3px 8px rgba(79, 163, 150, .8);
  }
  
  .product-section {
    margin-top: 30px;
    width: 100%;
  }
  
  .category-product-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    color: white;
  }
  
  .category-product-item {
    width: calc(33.33% - 10px);
    text-align: center;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
    background-color: #444444;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .product-image {
    width: 100%; /* Make the image take the full width of its container */
    max-width: 150px; /* Set the maximum width */
    height: auto; /* Allow the height to adjust based on the aspect ratio */
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .category-text-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-size: 14px;
    width: 100%;
  }
  
  .vertical-line {
    width: 1px;
    height: 100%;
    background-color: white;
    margin: 0 5px;
  }
  
  /* Adjust for smaller screens */
  @media screen and (max-width: 767px) {
    .main-category-container {
      margin-top: 75px;
      max-width: 100%;
    }
  
    .category-product-item {
      width: calc(50% - 20px); /* Adjust to two items per row on smaller screens */
      margin-bottom: 20px;
    }
  
    .product-image {
      width: 100%; /* Make sure the image takes the full width of its container */
      max-width: 100px; /* Reduce the maximum width for smaller screens */
      height: auto;
      margin: 0 auto;
      object-fit: cover;
    }
  
    .category-product-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .category-text-container {
      justify-content: center;
    }
  
    .category-left, .category-right {
      text-align: center;
    }
  }
  