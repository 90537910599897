.go-back-container {
    margin-top: 60px;
    margin-bottom: 30px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    color: #f8f4f0;
    font-family: 'Sovereign';
    font-size: 18px;
  }
  
  .arrow {
    position: absolute;
    left: -20px; /* Adjust the positioning of the arrow */
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .text:hover + .arrow {
    opacity: 1;
  }

  /* Adjust for smaller screens */
@media screen and (max-width: 767px) {
  .go-back-container {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}