.product-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(248, 244, 240); /* Change the text color */
  padding: 20px;
  border-radius: 8px;
  max-width: 1000px;
  width: 100%;
  z-index: 1001;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-height: 90vh;
  overflow-y: auto;
}

.product-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.modal-content {
  display: flex;
  flex-direction: row; /* Web view: two columns */
  align-items: flex-start;
  color: black;
}

.modal-body-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal-title {
  font-size: 22px;
  margin-bottom: 10px;
  font-family: 'Sovereign', serif;
  text-shadow: 6px -3px 8px rgba(211, 170, 26, 0.6);
}

.modal-image-container {
  width: 50%; /* Reduce the width to 50% of the parent container */
  max-width: 300px; /* Optionally, limit the maximum width */
  margin-bottom: 20px;
  text-align: center; /* Center the image within the container */
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.modal-body-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal-price {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Sovereign', serif;
}

.modal-description-container {
  margin-bottom: 20px;
}

.description-title {
  font-family: 'Sovereign', serif;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 6px -3px 8px rgba(211, 170, 26, 0.6);
  margin-bottom: 30px;
  margin-top: 30px;
}

.modal-description {
  font-family: 'Sovereign', serif;
  font-size: 14px;
  
}

.price-options {
  margin-top: 20px;
  font-family: 'Sovereign', serif;
  font-size: 16px;
}

.price-option {
  display: block;
  margin-bottom: 10px;
}

.add-to-cart-button {
  margin-top: 15px;
  padding: 15px 30px;
  background-color: #4fa396;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-family: 'Sovereign', serif;
  font-size: 18px;
  width: 200px;
}

.add-to-cart-button:hover {
  background-color: rgba(211, 170, 26, 0.6);
} 


.close-button {
  align-self: flex-end;
  font-size: 16px;
  padding: 10px 20px;
  background-color: transparent;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.close-button:hover {
  background-color: rgba(211, 170, 26, 0.6);
}

.add-to-cart-notification {
  background-color: #a3744f;
  color: white; /* White text */
  padding: 10px 15px; /* Padding for a compact look */
  border-radius: 5px; /* Rounded corners */
  font-family: 'Sovereign', serif;
  font-size: 14px; /* Font size */
  margin-top: 30px; /* Some space above the notification */
  text-align: center; /* Center the text */
  width: 170px; /* Make it span the full width of its container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow */
  opacity: 0.9; /* Slight transparency */
  transform: translateY(100%); /* Start off-screen (below the view) */
  transition: transform 0.5s ease, opacity 0.5s ease; /* Smooth slide and opacity transition */
}

.add-to-cart-notification.slide-in {
  transform: translateY(0); /* Slide into view */
}

.add-to-cart-notification.slide-out {
  transform: translateY(100%); /* Slide out of view */
  opacity: 0; /* Optionally fade out during slide */
}


/* Media query for mobile view */
@media (max-width: 768px) {
  .modal-content {
    flex-direction: column; /* Stack elements vertically in mobile view */
    align-items: center;
  }

  .modal-body-left,
  .modal-body-right {
    width: 100%;
    align-items: center;
  }

  .modal-title {
    order: 1;
    text-align: center;
    width: 100%;
  }

  .modal-image-container {
    order: 2;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
  }

  .modal-price {
    order: 4;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
  }

  .modal-description-container {
    order: 3;
    width: 100%;
    text-align: center;
  }

  .price-options {
    order: 4;
    width: 100%;
    text-align: center;
  }

  .add-to-cart-button {
    order: 6;
    width: 100%;
    text-align: center;
  }

  .close-button {
    order: 7;
    align-self: center;
    margin-top: 10px;
  }
}
