/* cartsummary.css */

.cart-summary {
    padding: 20px;
    border-radius: 5px;
    background-color: #343434; /* Set the background color */
    margin-top: 20px;
    font-family: 'Sovereign', serif;
    color: rgb(248, 244, 240); /* Change the text color */
    
}

.tax-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-family: 'Sovereign', serif;
    font-size: 10px;
    color: rgb(248, 244, 240); /* Change the text color */
    
}

.summary-item {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-family: 'Sovereign', serif;
    color: rgb(248, 244, 240); /* Change the text color */
}

.checkout-button {
    display: block;
    width: 200px; /* Set a fixed width */
    padding: 10px 0;
    font-family: 'Sovereign', serif;
    background-color: #4fa396;
    color: white;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 20px;
    margin-left: auto; /* Align to the right by default */
    margin-right: 0; /* Align to the right by default */
}


.checkout-button:hover {
    background-color: rgba(211, 170, 26, 0.6);
  } 

.checkout-button:disabled {
    background-color: #999;
    cursor: not-allowed;
}

.error-message {
    color: red;
    margin-bottom: 10px;
    font-size: 0.9em;
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .checkout-button {
        margin-left: auto; /* Center the button horizontally */
        margin-right: auto; /* Center the button horizontally */
    }
}