/* Smaller, modernized styles for the filter section */
.filter-section {
  display: flex;
  flex-direction: column;
  gap: 12px; /* Reduced gap */
  margin-bottom: 16px; /* Smaller margin */
  padding: 12px; /* Smaller padding */
  border: 1px solid #7f7f7f;
  border-radius: 8px; /* Slightly smaller border radius */
  background-color: #7f7f7f;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Smaller shadow */
}

/* Style for each label */
.filter-section label {
  font-weight: 600;
  margin-bottom: 6px; /* Reduced margin */
  display: block;
  color: #D3AA1A;
  font-family: 'Sovereign', serif;
  font-size: 14px; /* Smaller font size */
}

/* Styling the select dropdowns */
.filter-section select {
  width: 100%;
  padding: 8px; /* Reduced padding */
  border: 1px solid #d1d1d1;
  border-radius: 4px; /* Smaller border radius */
  background-color: #fff;
  font-size: 14px; /* Smaller font size */
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Styling for individual input fields */
.filter-section input {
  padding: 8px; /* Reduced padding */
  margin-top: 6px; /* Slightly smaller margin */
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  width: 100%;
  font-size: 14px; /* Smaller font size */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

/* Hover effects for select and input */
.filter-section select:hover,
.filter-section input:hover {
  border-color: #999;
  background-color: #f9f9f9;
}

/* Responsive adjustments for larger screens */
@media (min-width: 768px) {
  .filter-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px; /* Reduced gap for larger screens as well */
  }

  .filter-section div {
    flex: 1;
    margin-right: 12px; /* Reduced margin between elements */
  }

  .filter-section div:last-child {
    margin-right: 0;
  }
}
