.cart-container {
    width: 100%; /* Set the width to match the homepage */
    max-width: 1050px; /* Adjust max-width as needed */
    margin: 0 auto; /* Center the container */
    text-align: center; /* Center the text and child elements */
    padding-top: 60px; /* Add some padding */
    background-color: #343434; /* Set the background color */    
    border-bottom: 1px solid #ccc; /* Add border around the filter component */
}

.cart-title {
    font-family: 'Bublest', serif; /* Change this to your desired font */
    font-size: 56px; /* Adjust the size as needed */
    color: rgb(248, 244, 240); /* Change the text color */
    text-shadow: 6px -3px 8px rgba(79, 163, 150, .8);
    margin-bottom: 20px; /* Add some space below the title */
}
   

.cart-item {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.cart-item img {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.cart-summary {
    margin-top: 20px;
    text-align: right;
}

.cart-summary h3 {
    margin-bottom: 10px;
}
