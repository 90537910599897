.consult-container {
    width: 100%; /* Set the width to match the homepage */
    max-width: 1050px; /* Adjust max-width as needed */
    margin: 0 auto; /* Center the container */
    text-align: center; /* Center the text and child elements */
    padding-top: 60px; /* Add some padding */
    background-color: #343434; /* Set the background color */
    margin-top: 40px;
    width: 100%;
    height: 100%;
    display: column;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc; /* Add border around the filter component */
}

.consult-title {
    font-family: 'Bublest', serif; /* Change this to your desired font */
    font-size: 56px; /* Adjust the size as needed */
    color: rgb(248, 244, 240); /* Change the text color */
    text-shadow: 6px -3px 8px rgba(79, 163, 150, .8);
    margin-bottom: 20px; /* Add some space below the title */
}

form {
    display: flex;
    flex-direction: column;
    width: 40%; 
    margin: 0 auto; /* Center the form horizontally */
  }
  
  form div {
    margin-bottom: 15px;
  }
  
  form label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  form input[type="text"],
  form input[type="email"],
  form input[type="tel"],
  form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
  }
  
  form textarea {
    height: 100px;
    resize: none;
  }
  
  form button {
    padding: 10px 20px;
    background-color: #4fa396;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  form button:hover {
    background-color: #D3AA1A;
  }
  
  .radio-group {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }
  
  .radio-group input {
    margin-right: 5px;
  }


  
  @media screen and (max-width: 767px) {

    .main-consult-container {
      margin-top: 75px; /* Adjust the margin to create more space below the header */
    }

    form {
        display: flex;
        flex-direction: column;
        width: 100%; 
        margin: 0 auto; /* Center the form horizontally */
      }
}  
