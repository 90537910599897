/* Dropdown menu styles */

.dropdown-menu {
  display: none; /* Initially hide the dropdown menu */
  position: absolute;
  top: 100%;
  left: 0;
  width: 150px;
  padding: 10px;
  z-index: 1000;
  height: auto;
  background-color: rgba(211, 170, 26); /* Background color for dropdown */
  transition: transform 0.3s ease; /* Add transition for smooth animation */
  transform-origin: top; /* Set transform origin to top for slide-down effect */
  display: flex;
  flex-direction: column; /* Arrange items in a column */
  border-radius: 10px; /* Add rounded corners */
  
}

/* Show dropdown menu when the 'show' class is added */
.dropdown-menu.show {
  display: flex;
  transform: translateY(0); /* Reset transform to show the menu */
}

/* Products dropdown container */
.products-dropdown {
  position: relative; /* Ensure dropdown is positioned relative to this container */

  

}

/* Dropdown item styles */
.dropdown-item {
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-family: 'Spot', sans-serif;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

/* Hover effect for dropdown items */
.dropdown-item:hover {
  background-color: #2c3e50; /* Change background color on hover */
}

/* Add background color and other styles to the Products button */
.dropdown-toggle {
  background-color: transparent; /* Set background color for the Products button */
  font-family: 'Spot', sans-serif;
  font-size: 18px;
  color: white; /* Set text color */
  padding: 10px 20px; /* Add padding for button */
  border: none; /* Remove border */
  border-radius: 5px; /* Add rounded corners */
  cursor: pointer; /* Add pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.dropdown-toggle:hover {
    color: rgba(211, 170, 26); /* Change background color on hover */
  }

/* Mobile view adjustments */
/* Mobile view adjustments */
@media (max-width: 768px) {
  .dropdown-menu {
    position: static; /* Aligns the dropdown with the flow of the document */
    width: 100%; /* Make the dropdown take up the full width of the mobile menu */
    padding: 0; /* Remove padding */
    background-color: rgba(211, 170, 26); /* Background color for dropdown */
    box-shadow: none; /* Remove shadow */
  }

  .dropdown-item {
    padding: 10px 20px; /* Adjust padding for mobile view */
    text-align: center; /* Center the text inside the dropdown items */
  }

  .products-dropdown {
    width: 100%; /* Ensure the dropdown container spans the full width */
    border-bottom: 1px solid #333; /* Border spans the full width */
    padding: 0; /* Remove padding if any */
  }

  .dropdown-toggle {
    width: 100%; /* Make sure the button spans the full width */
    padding: 10px 20px; /* Adjust padding */
    text-align: center; /* Center the text inside the button */
  }
}

