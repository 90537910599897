@font-face {
    font-family: 'Bublest';
    src: url('../../public/fonts/bublest-trippy/Bublest.otf') format('opentype');
    /* Add other font formats if needed */
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HyperBleach';
    src: url('../../public/fonts/hyper-bleach/HyperBleach.otf') format('opentype'),
         url('../../public/fonts/hyper-bleach/HyperBleach-Glossy.otf') format('opentype');
    /* Add other font formats if needed */
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HyperBleach-Gloss';
    src: url('../../public/fonts/hyper-bleach/HyperBleach-Glossy.otf') format('opentype');
    /* Add other font formats if needed */
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Mock-Bear';
    src: url('../../public/fonts/mock-bear/Mock-Bear.otf') format('opentype');
    /* Add other font formats if needed */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Peachy-Bubble';
    src: url('../../public/fonts/peachy-bubble/Peachy-Bubbly.otf') format('opentype');
    /* Add other font formats if needed */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Semulla';
    src: url('../../public/fonts/semulla/Semulla-Display.otf') format('opentype');
    /* Add other font formats if needed */
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Semulla-Outline';
    src: url('../../public/fonts/semulla/Semulla-Outline-Display.otf') format('opentype');
    /* Add other font formats if needed */
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Pulse-Semi';
    src: url('../../public/fonts/pulse/Pulse_Rounded_Semi_Bold.otf') format('opentype');
    /* Add other font formats if needed */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Reef';
    src: url('../../public/fonts/reef/Reef.otf') format('opentype');
    /* Add other font formats if needed */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sovereign';
    src: url('../../public/fonts/sovereign/Sovereign.otf') format('opentype');
    /* Add other font formats if needed */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Spot';
    src: url('../../public/fonts/spot/Spot-Normal.otf') format('opentype');
    /* Add other font formats if needed */
    font-weight: normal;
    font-style: normal;
  }
  
  
  
  