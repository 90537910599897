.home-container {
    width: 100%; /* Set the width to match the homepage */
    max-width: 1050px; /* Adjust max-width as needed */
    margin: 0 auto; /* Center the container */
    text-align: center; /* Center the text and child elements */
    padding-top: 60px; /* Add some padding */
}

.home-image {
    width: 100%; /* Make the image responsive */
    max-width: 600px; /* Set a max-width for the image */
    height: auto; /* Maintain aspect ratio */
}

.view-shop-button {
    margin: 20px auto; /* Center the button and add margin above */
    padding: 10px 20px;
    cursor: pointer;
    background-color: transparent;
    color:rgb(248, 244, 240);
    font-size: 72px; /* Increase the font size */
    font-family: 'Bublest', sans-serif;
    text-shadow: 0 4px 8px rgba(211, 170, 26, 0.8); /* Add shadow to the text */
    border: none;
    border-radius: 5px;
    display: block; /* Center the button horizontally */
}

.shop-links-container {
    background-color: #2c3e50; /* Background color for the container */
    padding: 20px; /* Padding inside the container */
    border-radius: 10px; /* Rounded corners for larger screens */
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    flex-wrap: wrap; /* Allow wrapping if there are too many links */
    margin-top: 20px;
    z-index: 1000; /* Ensure it is on top of other elements */
}

.shop-links {
    display: flex;
    align-items: center; /* Align items inline */
    justify-content: center; /* Center items on the page */
    width: auto; /* Make sure the container spans the required width */
    
}

/* Links styling */
.shop-links a {
    margin: 10px; /* Add margin around each link */
    text-decoration: none;
    color: rgb(248, 244, 240);
    font-size: 18px;
    padding: 10px 20px; /* Add padding inside the link */
    border-radius: 5px; /* Add rounded corners to links */
    font-size: 48px; /* Increase the font size */
    font-family: 'Bublest', sans-serif;
    transition: background-color 0.3s; /* Smooth transition for background color */
}

.shop-links a:hover {
    background-color: rgba(211, 170, 26, 0.8);
}

.close-shop-button {
    background-color: transparent; 
    cursor: pointer;
    color: rgb(248, 244, 240);
    border: none;
    font-size: 18px;
    margin-left: 20px; /* Add space between the last link and the close button */
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .shop-links-container {
        flex-direction: column; /* Stack items in a column */
        align-items: center; /* Center items in the column */
        justify-content: center; /* Center items vertically */
        width: 100%; /* Ensure full width */
        height: 100vh; /* Full viewport height */
        margin-top: 0; /* Remove top margin */
        padding: 0; /* Remove padding */
        border-radius: 0; /* Remove border radius */
        position: fixed; /* Fix position to cover the screen */
        top: 0; /* Position at the top */
        left: 0; /* Position at the left */
        overflow: hidden; /* Hide any overflow */
    }
    
    .shop-links {
        flex-direction: column; /* Stack links vertically on mobile */
        align-items: center; /* Center the links */
        width: 100%; /* Make the shop-links span full width */
    }
    
    .shop-links a {
        margin: 10px 0; /* Adjust margin for the column layout */
        text-align: center; /* Center text in each link */
    }
    
    .close-shop-button {
        margin-left: 0; /* Reset the left margin */
        margin-bottom: 10px; /* Add margin below the close button */
        position: absolute; /* Position the close button */
        top: 10px; /* Top of the screen */
        right: 10px; /* Right of the screen */
    }
}